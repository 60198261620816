const firebaseConfig = {
  apiKey: 'AIzaSyAGkaHQ8cCzzaMBg9X_mxho30NE6y5pDOM',
  authDomain: 'liam-portfolio.firebaseapp.com',
  projectId: 'liam-portfolio',
  storageBucket: 'liam-portfolio.appspot.com',
  messagingSenderId: '422810301817',
  appId: '1:422810301817:web:242c441c579aa89d5deba8',
}

export default firebaseConfig
